<h1 mat-dialog-title>
    {{ (group ? 'ACTION.GROUP.UPDATE' : 'ACTION.GROUP.ADD') | translate }}
</h1>
<form *ngIf="form" [formGroup]="form"
      autocomplete="off">
    <mat-dialog-content class="item-dialog">

        <!-- description -->
        <mat-form-field class="col-9">
            <input matInput
                   formControlName="description"
                   placeholder="Descrição"
                   required>
        </mat-form-field>

        <mat-form-field class="col-3">
            <mat-label>Categoria</mat-label>
            <mat-select formControlName="groupKind">
                <mat-option *ngIf="GROUP_KIND.part" [value]="GROUP_KIND.part">Peça</mat-option>
                <mat-option *ngIf="GROUP_KIND.labor" [value]="GROUP_KIND.labor">Serviço</mat-option>
                <mat-option *ngIf="GROUP_KIND.expense" [value]="GROUP_KIND.expense">Tipo de despesa</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- salePricePercentage -->
        <mat-form-field class="col-12 col-md-4">
            <mat-label>Percentual de reajuste</mat-label>
            <input matInput
                   formControlName="salePricePercentage"
                   money
                   required
                   maxlength="8">
            <span matTextSuffix
                  *ngIf="form.value.salePricePercentage">&nbsp;%</span>
        </mat-form-field>

        <!-- type -->
        <mat-form-field class="col-12 col-md-4">
            <mat-label>Tipo do item</mat-label>
            <mat-select formControlName="type">
                <mat-option *ngFor="let item of PART_TYPES"
                            [value]="PART_TYPES.indexOf(item) + 1">
                    {{ item }}
                </mat-option>
                <mat-option>-- Nenhum --</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- salePricePercentage -->
        <mat-form-field class="col-12 col-md-4">
          <mat-label>Comissão padrão</mat-label>
          <input matInput
                 formControlName="commissionDefault"
                 money>
          <span matTextSuffix
                *ngIf="form.value.commissionDefault">&nbsp;%</span>
        </mat-form-field>

        <!-- Commission -->
        @if (profile | canAccess: "RECORDS.groups.changeUsersCommision") {
          <mat-card appearance="outlined" class="w-100 outerSpan">
            <mat-card-subtitle>
                  {{ 'PROPERTY.USER.COMMISSIONS' | translate }}
            </mat-card-subtitle>
              <div class="d-flex align-items-center">
                <!-- value -->
                <mat-form-field class="col-4">
                  <mat-label>Porcentagem</mat-label>
      
                  <input matInput
                      money
                      [(ngModel)]="commissionValue"
                      [ngModelOptions]="{ standalone:true }"
                  />
                  <span matTextSuffix
                      *ngIf="commissionValue">&nbsp;%</span>
      
                </mat-form-field>
                <!-- user -->
                <mat-form-field class="col-5">
                  <mat-select #userSelect
                        placeholder="{{ 'OBJECT.USER' | translate }}"
                        [(ngModel)]="commissionUser"
                        [ngModelOptions]="{ standalone:true }"
                        required>
                    <mat-option *ngFor="let user of users"
                          [value]="user">
                      {{ user.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- ok -->
                <div class="col-3 d-flex justify-content-end">
                  <button mat-raised-button
                    type="button"
                    [disabled]="!commissionValue || !commissionUser"
                    (click)="addCommission()"
                    color="primary">
                    OK
                  </button>
                </div>
      
              </div>
      
              <!-- table of commissions -->
              <table mat-table
              #commissionsTable
              multiTemplateDataRows
              class="w-100"
              [dataSource]="u2"
              >
              <ng-container matColumnDef="value">
              <th mat-header-cell
                *matHeaderCellDef> Porcentagem </th>
              <td mat-cell
                *matCellDef="let row"> {{ row.value/100 | percent:'1.2' }} </td>
      
              </ng-container>
      
              <ng-container matColumnDef="user">
              <th mat-header-cell
                *matHeaderCellDef> {{ 'OBJECT.USER' | translate }} </th>
              <td mat-cell
                *matCellDef="let row"> {{ row.user.name  }} </td>
              </ng-container>
      
              <ng-container matColumnDef="actions">
              <th mat-header-cell
                class="txt-end"
                *matHeaderCellDef></th>
              <td mat-cell
                class="txt-end"
                *matCellDef="let row; let i = index">
                <button mat-icon-button
                    type="button"
                    [disabled]="false"
                    (click)="removeCommission(row)">
                  <mat-icon>clear</mat-icon>
                </button>
              </td>
              </ng-container>
      
              <tr mat-header-row
              *matHeaderRowDef="['value','user','actions']"></tr>
              <tr mat-row
              *matRowDef="let row; columns: ['value','user','actions']"></tr>
      
            </table>
          </mat-card>
        }

        @if (profile | canAccess: "RECORDS.groups.changeUsersDiscountControl") {
          <!-- Discount Control -->
          <mat-card appearance="outlined" class="w-100 outerSpan">
            <mat-card-subtitle>
                  {{ 'PROPERTY.USER.DISCOUNT' | translate }}
            </mat-card-subtitle>
            <div class="d-flex align-items-center">
              <!-- value -->
              <mat-form-field class="col-4">
                <mat-label>Porcentagem</mat-label>
                <input matInput
                    money
                    [(ngModel)]="discountValue"
                    [ngModelOptions]="{ standalone:true }"
                />
                <span matTextSuffix
                    *ngIf="discountValue">&nbsp;%</span>

              </mat-form-field>

              <!-- user -->
              <mat-form-field class="col-5">
                <mat-select #userSelect
                      placeholder="{{ 'OBJECT.USER' | translate }}"
                      [(ngModel)]="discountUser"
                      [ngModelOptions]="{ standalone:true }"
                      required>
                  <mat-option *ngFor="let user of users"
                        [value]="user">
                    {{ user.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- ok -->
              <div class="col-3 d-flex justify-content-end">
                <button mat-raised-button
                  type="button"
                  [disabled]="!discountValue || !discountUser"
                  (click)="addDiscount()"
                  color="primary">
                  OK
                </button>
              </div>

            </div>

            <!-- discounts table -->
            <table mat-table
              #discountTable
              multiTemplateDataRows
              class="w-100"
              [dataSource]="discountDataSource"
            >
              <ng-container matColumnDef="value">
                <th mat-header-cell
                  *matHeaderCellDef> Porcentagem </th>
                <td mat-cell
                  *matCellDef="let row"> {{ row.value/100 | percent:'1.2' }} </td>
              </ng-container>

              <ng-container matColumnDef="user">
                <th mat-header-cell
                  *matHeaderCellDef> {{ 'OBJECT.USER' | translate }} </th>
                <td mat-cell
                  *matCellDef="let row"> {{ row.user.name  }} </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell
                  class="txt-end"
                  *matHeaderCellDef></th>
                <td mat-cell
                  class="txt-end"
                  *matCellDef="let row; let i = index">
                  <button mat-icon-button
                      type="button"
                      [disabled]="false"
                      (click)="removeDiscount(row)">
                    <mat-icon>clear</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row
              *matHeaderRowDef="['value','user','actions']"></tr>
              <tr mat-row
              *matRowDef="let row; columns: discountColumns"></tr>

            </table>
          </mat-card>
        }


    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button aria-label="cancel"
                mat-raised-button
                color="accent"
                type="button"
                (click)="close()">
            {{ 'ACTION.SHARED.CANCEL' | translate }}
        </button>
        <button aria-label=save
                mat-raised-button
                color="primary"
                type="button"
                (singleClick)="onSubmit()"
                [disabled]="form.invalid">
            {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
        </button>
    </mat-dialog-actions>
</form>
